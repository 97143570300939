////////////////
//    Code    //
////////////////

.codeview {
    color: $textColor_white;
    background-color: rgba($textColor, 0.9);
    padding: 10px;
    margin-top: 40px;
    border-radius: 10px;
    text-align: left;
    text-shadow: none;

    .filename {
        border-bottom: 1px solid #666;
        margin-bottom: 1em;
    }

    pre {
        overflow: auto;
        white-space: pre-wrap;
        word-wrap: break-word;
    }
}
