////////////////
//    List    //
////////////////
.contact-list {
    font-size: 3.6rem;
    margin-top: 2rem;
    list-style: none;

    .list-item {
        display: inline-block;
        margin: 0 1rem;

        a {
            display: block;
            color: $textColor;

            &:hover {
                color: lighten($textColor, 20%);
            }
        }
    }


}
