//////////////////
//    Sprite    //
//////////////////
/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
$sprite-icon-a-name: 'sprite-icon_a';
$sprite-icon-a-x: 0px;
$sprite-icon-a-y: 0px;
$sprite-icon-a-offset-x: 0px;
$sprite-icon-a-offset-y: 0px;
$sprite-icon-a-width: 100px;
$sprite-icon-a-height: 100px;
$sprite-icon-a-total-width: 200px;
$sprite-icon-a-total-height: 200px;
$sprite-icon-a-image: 'src/img/sprite/';
$sprite-icon-a: (0px, 0px, 0px, 0px, 100px, 100px, 200px, 200px, 'src/img/sprite/', 'sprite-icon_a', );
$sprite-icon-b-name: 'sprite-icon_b';
$sprite-icon-b-x: 100px;
$sprite-icon-b-y: 0px;
$sprite-icon-b-offset-x: -100px;
$sprite-icon-b-offset-y: 0px;
$sprite-icon-b-width: 100px;
$sprite-icon-b-height: 100px;
$sprite-icon-b-total-width: 200px;
$sprite-icon-b-total-height: 200px;
$sprite-icon-b-image: 'src/img/sprite/';
$sprite-icon-b: (100px, 0px, -100px, 0px, 100px, 100px, 200px, 200px, 'src/img/sprite/', 'sprite-icon_b', );
$sprite-icon-c-name: 'sprite-icon_c';
$sprite-icon-c-x: 0px;
$sprite-icon-c-y: 100px;
$sprite-icon-c-offset-x: 0px;
$sprite-icon-c-offset-y: -100px;
$sprite-icon-c-width: 100px;
$sprite-icon-c-height: 100px;
$sprite-icon-c-total-width: 200px;
$sprite-icon-c-total-height: 200px;
$sprite-icon-c-image: 'src/img/sprite/';
$sprite-icon-c: (0px, 100px, 0px, -100px, 100px, 100px, 200px, 200px, 'src/img/sprite/', 'sprite-icon_c', );
$spritesheet-width: 200px;
$spritesheet-height: 200px;
$spritesheet-image: 'src/img/sprite/';
$spritesheet-sprites: ($sprite-icon-a, $sprite-icon-b, $sprite-icon-c, );
$spritesheet: (200px, 200px, 'src/img/sprite/', $spritesheet-sprites, );

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}
*/
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
