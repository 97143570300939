/*    Button    */
$button_borderRadius: 4px;

.button {
    a {
        display: block;
        border-radius: $button_borderRadius;
    }
}


$button_dl_width: 160px;
$button_dl_height: 50px;
$button_dl_fontSize: 2rem;

.button-dl {
    font-size: $button_dl_fontSize;
    display: inline-block;
    border: 3px solid $textColor;
    border-radius: 8px;
    margin-top: 40px;
    transition: all 0.3s ease 0s;
    text-shadow: none;
    @include headline_font;

    a {
        color: $textColor;
        width: $button_dl_width;
        height: $button_dl_height;
        line-height: $button_dl_height;
        text-decoration: none;
        transition: all 0.3s ease 0s;
    }

    &:hover {
        border-color: lighten($textColor, 30%);
        a {
            color: $textColor_white;
            background-color: lighten($textColor, 30%);
        }
    }
}
