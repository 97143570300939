///////////////
//    Bar    //
///////////////

/*    Headline-Underbar    */
$headlineUnderbar_width_large: 30%;
$headlineUnderbar_minWidth_large: 280px;
$headlineUnderbar_height_large: 10px;
$headlineUnderbar_marginTop_large: 25px;

$headlineUnderbar_width_small: 10%;
$headlineUnderbar_minWidth_small: 160px;
$headlineUnderbar_height_small: 6px;
$headlineUnderbar_marginTop_small: 16px;


[class*="headline-underbar"] {
    &:after {
        display: block;
        content: '';
        background-color: lighten($textColor, 30%);
        margin: 0 auto 0 auto;
        border-radius: 3px;
        box-shadow: $shadow;
    }
}

.headline-underbar-large:after {
    width: $headlineUnderbar_width_large;
    min-width: $headlineUnderbar_minWidth_large;
    height: $headlineUnderbar_height_large;
    margin-top: $headlineUnderbar_marginTop_large;
}

.headline-underbar-small:after {
    width: $headlineUnderbar_width_small;
    min-width: $headlineUnderbar_minWidth_small;
    height: $headlineUnderbar_height_small;
    margin-top: $headlineUnderbar_marginTop_small;
}
