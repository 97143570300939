/*    Common    */
.site-section {
	width: 100%;
	min-height: 100vh;
	padding: 60px;
	text-align: center;
	position: relative;
	box-sizing: border-box;
}

.site-inner {
    width: 100%;
	max-width: $width-base;
	padding: 20px;
	margin: 0 auto;
}

.site-title,
.site-headline {
    text-align: center;
    @include headline_font;
}

.site-headline {
	font-size: 3.6rem;
    height: 5rem;
    margin-bottom: 3rem;
}


/*    Site-Header    */
.site-header {
	background-color: lightblue;
}

.site-desc {
	line-height: 4rem;
	margin-top: 60px;
}

.site-title {
	font-size: 4.2rem;
	width: 100%;
	height: 1em;
    text-shadow: $shadow;
}


/*    Site-Main    */

.site-main {
    width: 100%;
}


/*    Pattern    */
#pattern {
	background-image: url(../img/pattern_japan.jpg);/* CSS側にimgを指定しておくことでオプションの記述を省略することも可能 */
	background-color: tomato;

	.site-inner { background-color: rgba(#fff, 0.8); }
}


/*    Repeat    */
$skyColor: lightblue;

#unpattern {
	background-color: $skyColor;
	background-position: 800px 200px, 600px 50px, 200px 400px, 100px 120px;/* 初期ポジション */
}


#option {
	background-color: #eee;
	.option-name {
		font-size: 3rem;
		&:not(:first-child) { margin-top: 40px; }
	}
	.option-desc { margin-top: 20px; }
}



.site-footer {
	.copyright {
		color: $textColor_white;
		background-color: $textColor;
		font-weight: normal;
		text-align: center;
		height: 50px;
		line-height: 50px;
		text-shadow: none;
	}
}
