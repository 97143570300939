///////////////////
//    Setting    //
///////////////////

@charset "utf-8";

// フォント
$fontSize-base: 10;
$fontSize-default: 16;
$fontUnit: rem;
$fontFamily: sans-serif;

// 色
$textColor: #333;
$textColor_white: #fff;

$linkColor: #39c;
$linkColor_hover: lighten($linkColor, 20%);

// シャドウ
$shadow: 0 0 5px rgba($textColor, 0.2);

// レイアウト
$width-base: 700px;

// ブレイクポイント
$mediaQuery-large: 768;
$mediaQuery-midium: 480;
$mediaQuery-small: 320;
