/*==============================
    Layout
==============================*/
html, body {
	font-size: $fontSize-base + px;
	width: 100%;
	height: 100%;
}

body {
	color: $textColor;
	font-family: $fontFamily;
	font-size: $fontSize-default / $fontSize-base + $fontUnit;
	font-weight: bold;
	line-height: 1.6em;
	text-shadow: 0 0 5px rgba(#fff, 0.5);
}

h1 { font-size: 3rem; }

h2 { font-size: 2.6rem; }

h3 { font-size: 2.4rem; }

a {
	color: $linkColor;

	&:hover { color: $linkColor_hover; }
}

small { font-size: 1.2rem; }

dt { font-weight: bold; }

img {
	display: block;
	width: 100%;
}
